import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import React, { Fragment } from 'react';
import Alert from './Alert';
import { Button, ButtonColors } from './Button';

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  error?: {
    active: boolean;
    title?: string;
    text: string;
    cancelText?: string;
  };
  confirm: () => void;
  close: () => void;
  loading?: boolean;
  icon?: React.ReactNode;
  primaryColor?: string;
  noColor?: boolean;
  children?: React.ReactNode;
  confirmDisabled?: boolean;
  isDelete?: boolean;
  hideConfirm?: boolean;
};

export const ConfirmModal: React.FC<Props> = ({
  title = 'Are you sure?',
  description,
  confirmText = 'Yes',
  cancelText = 'Cancel',
  error = { active: false, title: 'Error', text: '', cancelText: 'Close' },
  confirm,
  close,
  isOpen,
  loading = false,
  icon,
  primaryColor,
  noColor,
  children,
  confirmDisabled = false,
  isDelete = false,
  hideConfirm = false,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white dark:bg-darkGray-800 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 dark:bg-darkGray-700">
                    {icon || (
                      <CheckIcon
                        className={[
                          'h-6 w-6',
                          noColor || primaryColor ? '' : 'text-primary-600',
                        ].join(' ')}
                        style={{
                          color: primaryColor,
                        }}
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 dark:text-darkGray-50"
                    >
                      {title}
                    </Dialog.Title>

                    {error.active && (
                      <div className="w-full my-8">
                        {' '}
                        <Alert title={error.title} error={error.text} />
                      </div>
                    )}
                    {!error.active && !!description && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div>{children && children}</div>
                <div className="mt-6 flex flex-row gap-2">
                  <Button
                    text={
                      error.active ? error.cancelText || cancelText : cancelText
                    }
                    onClick={close}
                    fullWidth
                    color={ButtonColors.plain}
                    className="border border-gray-300 dark:border-darkGray-600"
                  />
                  {!hideConfirm && (
                    <Button
                      text={confirmText}
                      onClick={confirm}
                      fullWidth
                      loading={loading}
                      disabled={confirmDisabled || loading || error.active}
                      color={
                        isDelete
                          ? ButtonColors.red
                          : noColor || primaryColor
                            ? ButtonColors.plainWithBorder
                            : ButtonColors.primary
                      }
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

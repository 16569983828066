import { Dayjs } from 'dayjs';
import { TailwindColor } from './Color.type';
import { PatientTransactionItemType } from './PatientTransaction.type';
import { AppointmentWorkflow } from './Workflow.type';

export enum AppointmentStatuses {
  Scheduled = 'Scheduled',
  Canceled = 'Canceled',
  NoShow = 'No-Show',
  CheckedIn = 'Checked In',
  Deleted = 'Deleted',
}
export const AppointmentStatusOptions = Object.values(AppointmentStatuses).map(
  (status) => ({
    text: status,
    value: status,
  }),
);

export enum AppointmentTypes {
  NewPatient = 'New Patient',
  ExistingPatient = 'Existing Patient',
}

export enum BookingMethods {
  StaffAssisted = 'Staff Assisted',
  SelfScheduled = 'Self Scheduled',
}

export const disableStatusesInPast = [
  AppointmentStatuses.CheckedIn,
  AppointmentStatuses.Canceled,
  AppointmentStatuses.NoShow,
];

export type AppointmentBase = {
  id: string;
  patientId: string;
  treatmentId: number;
  disciplineId: number;
  checkInTime?: number;
  timeOfDay?: string;
  duration: number;
  clinicianId: string;
  clinicId: number;
  locationId: number;
  status: AppointmentStatuses;
  notes: string;
  top?: number;
  bottom?: number;
  left?: number;
  width?: number;
  height?: number;
  startTimeFormatted?: string;
  deleted?: boolean;
  optOutSMS?: boolean;
  daysOfWeek?: string[];
  recurringAppointmentId?: string;
  endRecurring?: any;
  displayValues: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
    patientPhone?: string;
    patientEmail?: string;
    hasActiveInsurance?: boolean;
    deniedPatient?: boolean;
    pendingPatient?: boolean;
    caseType?: number;
    nextRequestedEncounterDate?: string;
  };
  pendingPatient?: boolean;
  deniedPatient?: boolean;
  surveySent?: string;
  type?: AppointmentTypes;
  services?: PatientTransactionItemType[];
  policies?: AppointmentInsuranceType[];
  hasBillingTransaction?: boolean;
  cancellationReason?: string;
  courtesyBilling?: boolean;
  superBill?: boolean;
  tz?: string;
  bookingMethod?: BookingMethods;
  newPatient?: boolean;
  patientName?: string;
  treatmentName?: string;
  assessmentCodes?: any[];
  encounterSigned?: boolean;
  appointmentWorkflow?: AppointmentWorkflow;
  roomId?: number | null;
};

export type Appointment = AppointmentBase & {
  startTime: number;
  suppressAutomatedSurveys?: boolean;
  suppressReminders?: boolean;
};

export type AppointmentForUI = AppointmentBase & {
  inPast?: boolean;
  color?: TailwindColor;
  previous?: AppointmentForUI;
  latest?: AppointmentForUI;
  startTime: Dayjs;
  customDuration: number;
  surveys?: string[];
};

export type AppointmentForEither = AppointmentBase & {
  startTime: number | Dayjs;
};

export type AppointmentInsuranceType = {
  ID: number;
  appointmentID?: string;
  billingPriority: number;
  clinicID: number;
  deductible: number;
  insuranceID: string;
  insuranceName: string;
  insuranceProgram?: string; // Used for claim creation.
  patientID?: string;
  disciplineID: number;
  coPay?: number;
  coInsurance?: number;
  payorID?: number;
  serviceAllowedAmounts?: {
    [key: string | number]: {
      payorID: number;
      allowedAmount?: number | string | null;
    };
  };
  inNetwork?: boolean;
  billable?: boolean;
  courtesyBilling?: boolean;
  updatePatientInsurance?: boolean;
  allowedAmount?: number | string | null;
  maxPerVisit?: number | string | null;
  payorId?: number;
};

export type ServicePayor = {
  ID?: number;
  allowedAmount?: number | string | null;
  code: string | number;
  payorID: number;
  insuranceID: string;
  billingRef: string;
  createdAt?: string;
  updatedAt?: string;
  deductible?: number;
  disciplineId?: number;
};

export type RecurringAppointmentData = {
  originalAppointmentUsedId?: string;
  duration?: number;
  patientId?: string;
  clinicianId?: string;
  treatmentId?: number;
  timestamps: {
    date: string;
    end: number;
    start: number;
  }[];
  displayValues?: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
  };
  locationId?: number;
};

export type RecurringAvailability = {
  treatmentId?: number;
  clinicianId?: string;
  patientId?: string;
  displayValues?: {
    patientName: string;
    clinicianName: string;
    treatmentName: string;
  };
  duration?: number;
  dataWithAllConflicts?: {
    id: number;
    conflicts: Appointment[];
    messages: {
      overrideMessage?: string;
      clinicLocationMessage?: string;
      providerMessage?: string;
    };
    timestamp: { date: string; end: number; start: number };
  }[];
};
export enum AppointmentTypes {
  Initial = 'initial',
  Interim = 'interim',
  Reevaluation = 'reevaluation',
}

export type AppointmentUser = {
  id: string;
  name: string;
  fname?: string;
  lname?: string;
  profileImage?: string;
  appointments: AppointmentForEither[];
  hours?: {
    start: number;
    end: number;
  }[];
  color?: string;
  isRoom?: boolean;
  overrides?: {
    date: string;
    start: string; 
    end: string; 
    color: string;
    name?: string;
    recurringId?: string;
    clinicianId?: string;
  }[];
};

export type Appointments = {
  [key: string]: {
    [key: string]: AppointmentUser;
  };
};

export type AvailableSlotsResponse = {
  // Date
  [key: string]: {
    // User ID
    [key: string]: AppointmentForUI[];
  };
};

export type NoShowCancelFees = {
  ID: number;
  name: string;
  type: string;
  structure: string;
  value: number;
  clinicId: number;
  deleted: number;
};

import { User } from '@chiroup/core/types/User.type';
import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';

const userService = () => {
  const list = async (params: {
    search?: string;
    limit?: number;
    skip?: number;
  }) => {
    return ChiroUpAPI.get('api', `/users`, {
      queryParams: params,
    });
  };

  const findOne = async (id?: string): Promise<User> => {
    const res = await ChiroUpAPI.get('api', `/users/${id}`, {});
    return res || {};
  };

  const update = async (user: Partial<User>) => {
    const { ID, ...restOfUser } = user;
    return ChiroUpAPI.put('api', `/users/${ID}`, {
      body: restOfUser,
    });
  };

  const updateUserClinic = async (
    value: any,
    userID?: string,
    clinicID?: number,
  ) => {
    const res = await ChiroUpAPI.put(
      'api',
      `/user/${userID}/clinics/${clinicID}`,
      {
        body: value,
      },
    );
    return res || {};
  };

  const addUserToClinic = async (
    value: any,
    userID: string,
    clinicID?: number,
    userSalesforceID?: string,
  ) => {
    const res = await ChiroUpAPI.post(
      'api',
      `/user/${userID}/clinics/${clinicID}`,
      {
        body: { ...value, userSalesforceID },
      },
    );
    return res || {};
  };

  const del = (userId: string) => {
    return ChiroUpAPI.del('api', `/user/${userId}`, {});
  };

  const create = async (val: Partial<User>) => {
    return ChiroUpAPI.post('api', `/users`, {
      body: val,
    });
  };

  const removeFromClinic = async (userID: string, clinicID: number) => {
    const res = await ChiroUpAPI.del('api', `/users/${clinicID}/${userID}`, {});
    return res;
  };

  const resetPassword = async (email?: string, fname?: string) => {
    return await ChiroUpAPI.post('api', `/users/password-reset`, {
      body: { email, fname },
    });
  };

  const convertToEd = async (
    userId?: string,
    value?: Record<string, unknown>,
  ) => {
    return ChiroUpAPI.put('api', `users/${userId}`, { body: value });
  };

  const provisionSandbox = async ({
    userID,
    email,
    password,
    active = true,
  }: {
    userID: string;
    email: string;
    password?: string;
    active?: boolean;
  }) => {
    return await ChiroUpAPI.put('api', `/users/sandbox`, {
      body: { userID, email, password, active },
    });
  };

  return {
    list,
    findOne,
    update,
    del,
    create,
    removeFromClinic,
    updateUserClinic,
    addUserToClinic,
    resetPassword,
    convertToEd,
    provisionSandbox,
  };
};

export default userService();
